<template>
  <div id="settings-wrapper">
    <v-card
      id="settings"
      class="py-2 px-3"
      color="rgba(0, 0, 0, .3)"
      dark
      flat
      link
      min-width="90"
      style="position: fixed; bottom: 0px; right: -35px; border-radius: 8px;"
    >
      <v-icon large>
        mdi-cog
      </v-icon>
    </v-card>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      activator="#settings"
      bottom
      content-class="v-settings"
      left
      nudge-left="8"
      offset-x
      origin="top right"
      transition="scale-transition"
    >
      <v-card
        class="text-center mb-0"
        width="300"
      >
        <v-card-text>
          <strong class="mb-3 d-inline-block">SIDEBAR FILTERS</strong>

          <v-item-group v-model="color">
            <v-item
              v-for="color in colors"
              :key="color"
              :value="color"
            >
              <template v-slot="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="color"
                  class="v-settings__item"
                  size="25"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 secondary" />

          <strong class="mb-3 d-inline-block">SIDEBAR BACKGROUND</strong>

          <v-item-group
            v-model="scrim"
            @change="switchBarColor(scrim)"
          >
            <v-item
              v-for="scrim in scrims"
              :key="scrim"
              :value="scrim"
              class="mx-1"
            >
              <template v-slot="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="scrim"
                  class="v-settings__item"
                  size="24"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 secondary" />

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Dark Mode
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="$vuetify.theme.dark"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
                @change="switchTheme"
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Sidebar Mini
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="internalValue"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Sidebar Image
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="showImg"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <strong class="mb-3 d-inline-block">IMAGES</strong>

          <v-slide-group
            v-model="image"
            class="d-flex justify-space-between mb-3"
            show-arrows
            @change="switchBarImage(image)"
          >
            <v-slide-item
              v-for="image in images"
              :key="image"
              :value="image"
              class="mx-1"
            >
              <template v-slot="{ active, toggle }">
                <v-sheet
                  :class="active && 'v-settings__item--active'"
                  class="d-inline-block v-settings__item"
                  @click="toggle"
                >
                  <v-img
                    :src="require(`@/assets/background/sidebar/${image}`)"
                    height="100"
                    width="50"
                  />
                </v-sheet>
              </template>
            </v-slide-item>
          </v-slide-group>

          <div class="my-12" />
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'
  import { mapMutations, mapState } from 'vuex'

  export default {
    name: 'DashboardCoreSettings',

    mixins: [Proxyable],

    data: () => ({
      color: '',
      colors: [
        '#9C27b0',
        '#00CAE3',
        '#4CAF50',
        '#ff9800',
        '#E91E63',
        '#FF5252',
      ],
      image: 'sidebar-12.jpg',
      defaultImage: 'sidebar-12.jpg',
      images: [
        'sidebar-1.jpg',
        'sidebar-2.jpg',
        'sidebar-4.jpg',
        'sidebar-5.jpg',
        'sidebar-6.jpg',
        'sidebar-8.png',
        'sidebar-12.jpg',
      ],
      menu: false,
      scrim: 'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
      scrims: [
        'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
        'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
        'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
      ],
      showImg: true,
    }),

    created () {
      this.color = this.$store.getters.primary
    },

    computed: {
      ...mapState(['barImage']),

      isDark: function () {
        // console.log('current Theme', this.$store.getters.currentTheme)
        return this.$store.getters.currentTheme
      },
    },

    watch: {
      color (val) {
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = val
        localStorage.setItem('primary', val)
      },

      showImg (val) {
        if (!val) {
          // console.log('!val')
          this.setBarImage('sidebar-0.jpg')
          localStorage.setItem('barImage', 'sidebar-0.jpg')
        } else {
          // console.log('saveImage2')
          this.setBarImage(this.defaultImage)
          localStorage.setItem('barImage', this.defaultImage)
        }
      },
      image (val) {
        this.setBarImage(val)
      },
      scrim (val) {
        this.$store.commit('SET_SCRIM', val)
      },
    },

    methods: {
      ...mapMutations({
        setBarImage: 'SET_BAR_IMAGE',
      }),

      switchTheme () {
        localStorage.setItem('theme', this.$vuetify.theme.dark)
      },

      switchBarImage (val) {
        // console.log('setBarImage')
        localStorage.setItem('barImage', val)
      },

      switchBarColor (val) {
        // console.log('setScrim')
        localStorage.setItem('barColor', val)
      },
    },
  }
</script>

<style lang="sass">
  .v-settings
    .v-slide-group__next, .v-slide-group__prev
      min-width: 0px !important

    .v-item-group > *
      cursor: pointer

    &__item
      border-width: 3px
      border-style: solid
      border-color: transparent !important

      &--active
        border-color: #00cae3 !important
</style>
